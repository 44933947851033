// Imports
// ------
import React from 'react';
import { Helmet } from 'react-helmet';

// Component
// ------
function Seo({ meta }) {
	return (
		<Helmet>
			<title>{meta.title}</title>
			<link rel="canonical" href={typeof window !== 'undefined' && window.location.origin} />
			<meta name="description" content={meta.desc} />
			{meta.image && <meta name="image" content={meta.image.url} />}

			<meta property="og:url" content={typeof window !== 'undefined' && window.location.origin} />
			<meta property="og:type" content="article" />
			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={meta.description} />
			{meta.image && <meta property="og:image" content={meta.image.url} />}

			<meta name="twitter:card" content={meta.card} />
			<meta name="twitter:creator" content='173Tech' />
			<meta name="twitter:title" content={meta.title} />
			<meta name="twitter:description" content={meta.description} />
			{meta.image && <meta name="twitter:image" content={meta.image.url} />}
		</Helmet>
	);
}

export default Seo;
